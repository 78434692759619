<script>
import utilsMixin from '@shared/mixins/utils';
import PaymentMethodsCard from '@school/components/PaymentMethodsCard.vue';

export default {
  mixins: [utilsMixin],
  components: { PaymentMethodsCard },
  computed: {
    purchases() {
      return this.$store
        .getters['auth/customerPurchases']
        .map((v) => ({ ...v, amount: v.amount || 0 }));
    },
    hasPaymentMethod() {
      const customer = this.$store.getters['auth/customer'];
      return (
        customer.card_exp_month != null
        && customer.card_exp_year != null
        && customer.card_last4 != null
      );
    },
  },
};
</script>

<template>
  <div>
    <PaymentMethodsCard v-if="hasPaymentMethod" />

    <b-table
      v-if="purchases && purchases.length > 0" class="box" :data="purchases">
      <b-table-column v-slot="{ row }" field="name" label="Produit" sortable>
          <b-tooltip :label="row.entity.name">
            <span class="is-block mw-260 has-text-clipped">
              {{ row.entity.name }}
            </span>
          </b-tooltip>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="created_at" label="Date d'achat" sortable numeric>
        <b-tooltip :label="$moment.utc(row.created_at).local().format('DD/MM/YY [à] HH:mm')">
          {{ row.created_at | momentFromUTC | moment('from') }}
        </b-tooltip>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="amount" label="Prix d'achat" sortable numeric>
        {{ row.amount | formatPrice }}
      </b-table-column>
    </b-table>
  </div>
</template>
